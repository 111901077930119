<template>
      <div class="index container">

        <transition name="fade">
          <router-view @showParent="showParent">
          </router-view>
        </transition>
        
        <div v-if="showParentPage">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item
              :exact="true"
              :to="{ name: 'labelGen', params: {} }"
              active
            >
              Label Gen
            </v-breadcrumbs-item>
          </v-breadcrumbs>

        <div class="d-flex flex-column flex-lg-row">
          <v-text-field
            dense
            v-model="searchTerms"
            @keyup.enter="search"
            @click:clear="search"
            @click:append="search"
            placeholder="Press enter to begin search"
            append-icon="mdi-magnify"
            outlined
            clearable
            label="Search"
          />
          <v-btn
            class="ml-0 ml-lg-4 mb-2 mb-lg-0 align-self-end align-self-lg-start"
            color="primary"
            :to="{ name: 'labelGenCreate', params: {} }"
          >
            Create Label Gen
          </v-btn>
        </div>

        <div class="table-container">
          <v-data-table
            :headers="headers"
            :items="labelGenList"
            :items-per-page="pagination.pageSize"
            :loading="isAppLoading"
            :server-items-length="pagination.totalSize"
            :page="pagination.current"
            :disable-sort="true"
            item-key="id"
            class="elevation-1"
            @update:options="pageChanged"
          >
            
            <template v-slot:item.product="{ item }">
              <a @click="view(item)">{{ item.brand }}</a>
            </template>
            
            <template v-slot:item.brand="{ item }">
              <a @click="view(item)">{{ item.brand }}</a>
            </template>
            
            <template v-slot:item.inventory_id="{ item }">
              <a @click="view(item)">{{ item.inventory_id }}</a>
            </template>
            
            <template v-slot:item.prefix="{ item }">
              <a @click="view(item)">{{ item.prefix }}</a>
            </template>
            
            <template v-slot:item.code="{ item }">
              <a @click="view(item)">{{ item.code }}</a>
            </template>
            
            <template v-slot:item.weights="{ item }">
              <a @click="view(item)">{{ item.weights }}</a>
            </template>
            
            <template v-slot:item.stock_date="{ item }">
              <a @click="view(item)">{{ item.stock_date | formatDateYear}}</a>
            </template>
            
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="edit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="remove(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
      <confirmation-dialog ref="confirm" />
    </div>
  </template>

    <script>
    import { mapActions, mapGetters } from "vuex";
  
    export default {
      data(){
        return {
          pagination: {
            current: 1,
            pageSize: 10,
            totalSize: 100
          },
          labelGenList: [],
          showParentPage: true,
          
      headers: [
        {
          text: "Product",
          value: "product_name",
          align: "start",
          sortable: true
        },
        {
          text: "Brand",
          value: "brand",
          align: "start",
          sortable: false
        },
        {
          text: "Inventory",
          value: "inventory_id",
          align: "start",
          sortable: false
        },
        {
          text: "Prefix",
          value: "prefix",
          align: "start",
          sortable: false
        },
        {
          text: "Code",
          value: "code",
          align: "start",
          sortable: false
        },
        {
          text: "Weights",
          value: "weights",
          align: "start",
          sortable: false
        },
        {
          text: "Stock Date",
          value: "stock_date",
          align: "start",
          sortable: false
        },
        
        { text: "Actions", value: "actions", align: "end", sortable: false }
      ],
          sort: {
            key: null
          },
          searchTerms : ''
        }
      },
      computed: {
        ...mapGetters("labelGen", ["labelGenProcessed", "getLabelGens"]),
        ...mapGetters("navigation", ["isAppLoading"])
      },
      methods: {
        ...mapActions("messages", [
          "addErrorMessage",
          "addMessage",
          "addSuccessMessage"
        ]),
        ...mapActions("labelGen", ["deleteLabelGen", "fetchLabelGens"]),
        view(item){
          this.$router.push({ name: 'labelGenEditor', params: { id: item.id }})
        },
        edit(item){
          this.$router.push({ name: "labelGenEdit", params: { id: item.id }})
        },
        async remove(item){
          let confirm = await this.$refs.confirm.open(
            "Confirm Delete",
            "Do you want to delete this labelGen?"
          );
          if(confirm){
            this.deleteLabelGen(item.id)
              .then( () => {
                this.addSuccessMessage("labelGen was deleted successfully");
                this.getResources();
              }).catch( () => {
                this.addErrorMessage("Server response with error ");
              });
          }
        },
        getResources() {
          const params = {
            page: this.pagination.pageIndex && this.pagination.pageIndex > 0 ? this.pagination.pageIndex-1:0,
            size: this.pagination.pageSize,
            sort: 'stockDate,desc',
            prefix: this.searchTerms,
            code: this.searchTerms,
          };
          this.fetchLabelGens(params)
            .then(response => {
              if(response){
                this.pagination.totalSize = response.totalElements;
                this.labelGenList = response.content;
              }
            })
            .catch(error => {
              this.addErrorMessage("Server response with error " + error);
            });
        },
        showParent(show) {
          this.showParentPage = show;
        },
        pageChanged(pagination) {
          this.pagination.pageIndex = pagination.page;
          this.pagination.pageSize = pagination.itemsPerPage;

          this.getResources()
        },
        search(){
          this.getResources();
        }
      },
      watch: {
        'pagination.numberRegisterForPage': function(){
            this.pagination.current = -1;
        },
      },
      beforeRouteUpdate(to, from, next) {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        this.showParentPage = toDepth < fromDepth;
        if (this.showParentPage) {
          this.getResources();
        }
        next();
      }
    }
    </script>

    